import React from 'react';
import '../../CSS/Search.css';

// Component for the first image design
export const SearchBar = () => {
  return (
    <div className={"search-container"}>
      <div className={"search-container-inner"}>
        <div className={"search-section"}>
          <div className={"search-dropdown-input"}>
            <i class="bi bi-search"></i>
            <p>What are you looking for today</p>
            <i class="bi bi-chevron-down clickable-icon" onClick={(e) => {console.log(e)}}></i>
          </div>
          <div className={"search-location-input"}>
            <i class="bi bi-geo-alt clickable-icon" onClick={(e) => {console.log(e)}}></i>
            <p>Langatta, NHC</p>
          </div>
          <i class="bi bi-search search-icon"></i>
        </div>
        <div className={"buttons-section"}>
          <p className={"buttons-section-title"}>You may be looking for</p>
          <div className={"button-items"}>
            <div className={"best-services-chip"}>
              Emergency Childcare
              <i className={"bi bi-x"}></i>
            </div>
            <div className={"best-services-chip"}>
              Newborn Care
              <i className={"bi bi-x"}></i>
            </div>
            <div className={"best-services-chip"}>
              Toddler Care
              <i className={"bi bi-x"}></i>
            </div>
            <button className={"more-button"} type={"button"} onClick={(e) => {console.log("Navigate to page xyz")}}>More</button>
          </div>
        </div>
      </div>
    </div>
  );
};
