import React from "react";
import "../CSS/terms.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Disclaimer = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-container">

      <div className="terms-header">
        <h3>Disclaimer </h3>
        <p>Effective Date: [1/12/2024]</p>
      </div>
      
      <div className="terms-content">
        <p>
          The information provided on this website has been compiled with the
          utmost care by Nannies Desk. However, the content on this website may
          contain inaccuracies, errors, or typographical mistakes. By accessing
          or using this website, you acknowledge and agree to the following
          terms:
        </p>

        <h4>1. General Disclaimer</h4>
        <p>
          Nannies Desk makes no guarantees, warranties, or representations,
          express or implied, about the completeness, accuracy, reliability, or
          availability of the information provided on this website. We are not
          responsible or liable for any loss or damage arising from reliance on
          the information provided on this website.
        </p>
        <p>
          We reserve the right to make changes, corrections, or improvements to
          the content on this website at any time without prior notice.
        </p>

        <h4>2. Limitation of Liability</h4>
        <ul>
          <li>
            Nannies Desk shall not be held liable for any direct, indirect,
            incidental, consequential, or punitive damages arising from access
            to or use of this website.
          </li>
          <li>
            Losses caused by incorrect, incomplete, or outdated information on
            the website.
          </li>
          <li>
            Losses arising from the inability to use this website, delays, or
            interruptions, whether caused by technical faults or other
            circumstances beyond our control.
          </li>
        </ul>
        <p>
          Nannies Desk also disclaims liability for any loss resulting from the
          use of electronic communication, including but not limited to delayed
          transmission, non-delivery of messages, interception by unauthorized
          parties, or the presence of viruses.
        </p>

        <h4>3. Third-Party Claims and Indemnity</h4>
        <p>
          By using this website, you agree to indemnify and hold Nannies Desk
          harmless from any claims, losses, or damages brought by third parties
          as a result of:
        </p>
        <ul>
          <li>
            Your violation of the terms and conditions of using this website.
          </li>
          <li>Unauthorized or improper use of the website.</li>
          <li>
            Breach of any law, regulation, or third-party rights while using
            this website.
          </li>
        </ul>

        <h4>4. External Links</h4>
        <p>
          This website may contain links to third-party websites. Nannies Desk
          is not responsible for the content, policies, or practices of these
          external websites and does not endorse or make representations about
          them. Use of these third-party websites is at your own risk.
        </p>

        <h4>5. Use of Website and Governing Law</h4>
        <p>
          By accessing and using this website, you agree to use it in compliance
          with applicable Kenyan laws, including but not limited to the Kenya
          Data Protection Act, 2019, Consumer Protection Act, 2012, and other
          relevant regulations.
        </p>
        <p>
          This disclaimer and any disputes arising out of your use of the
          website shall be governed by and construed in accordance with the laws
          of Kenya. Any legal proceedings shall be subject to the jurisdiction
          of the courts of Kenya.
        </p>

        <h4>6. No Guarantee of Continuous Access</h4>
        <p>
          While we strive to ensure the website is accessible at all times,
          Nannies Desk does not guarantee uninterrupted or error-free access. We
          reserve the right to suspend, withdraw, or modify the services
          provided through this website without prior notice.
        </p>

        <h4>7. Updates to Disclaimer</h4>
        <p>
          Nannies Desk reserves the right to update, revise, or modify this
          disclaimer at any time without prior notice. It is your responsibility
          to regularly review this page for any changes. Continued use of the
          website constitutes acceptance of the revised disclaimer.
        </p>

        <h4>Contact Information</h4>
        <p>For further inquiries or concerns, please contact us:</p>
        <ul>
          <li>Email: info@nanniesdesk.com</li>
          <li>Phone: +254722525689</li>
        </ul>

        <p>
          By using the Nannies Desk website, you confirm that you understand and
          agree to the terms of this disclaimer.
        </p>
      </div>

      <div className="terms-footer">
        <Button
          style={{
            backgroundColor: "#DE1877",
            color: "white",
            borderRadius: "40px",
            padding: "8px 32px",
          }}
          variant="contained"
          onClick={() => navigate("/")}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Disclaimer;
