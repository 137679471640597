import React, { useEffect, useState } from "react";
import {
  Modal,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import "../CSS/MpesaPaymentModal.css";
import { postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";
import { getLocalStorageVariable } from "./NavBar";
import { Chef, Gardener, homeOwner, houseHelp } from "../Shared/sharedLists";

const MpesaPaymentModal = ({
  isOpen,
  onClose,
  onSuccess,
  connectId,
  regType,
}) => {
  const [paymentPhoneNumber, setPaymentPhoneNumber] = useState("");
  const [amountPayable, setAmountPayable] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const fetchAmountPayable = async () => {
        try {
          const response = await postDataToAPI(
            "/dashboard/fetchAmountPayable.php",
            {}
          );
          if (response) {
            if (regType === homeOwner) {
              setAmountPayable(response.homeOwnerAmount);
            } else if (regType === "jobApplication") {
              setAmountPayable(response.jobApplicationAmmount);
            } else if (regType === houseHelp || regType === Chef || regType === Gardener) {
              setAmountPayable(response.houseHelpAmount);
            } else if (regType === "jobPosting") {
              setAmountPayable(response.jobPostAmmount);
            }
             else if (regType === "completeProfile") {
              setAmountPayable(250);
            }
          } else {
            setErrorMessage("Failed to fetch the payable amount.");
          }
        } catch (error) {
          setErrorMessage("Error fetching the amount. Please try again later.");
        }
      };

      fetchAmountPayable();
    }
  }, [isOpen, regType]);

  const handlePhoneNumberChange = (e) => {
    setPaymentPhoneNumber(e.target.value);
    if (errorMessage) setErrorMessage("");
  };

  const onPaymentSubmit = async (paymentPhoneNumber, amountPayable) => {
    const userEmail = getLocalStorageVariable("userEmail");
    let intervalId;
    let timeoutId;

    try {
      setIsButtonDisabled(true);

      const paymentData = new FormData();
      paymentData.append("phoneNumber", paymentPhoneNumber);
      paymentData.append("amount", amountPayable);
      paymentData.append("connectId", connectId);
      paymentData.append("userEmail", userEmail);

      console.log("connectId: ", connectId);
      
      if (regType === "jobApplication") {
        paymentData.append("paymentType", "jobApplication");
      } else if (regType === "jobPosting") {
        paymentData.append("paymentType", "jobPosting");
      } else {
        paymentData.append("paymentType", "connect");
      }

      const rawResponse = await postDataToAPI(
        "/mpesa/processMpesaPayment.php",
        paymentData
      );

      const jsonStartIndex = rawResponse.indexOf("{");
      const paymentResponse = JSON.parse(rawResponse.slice(jsonStartIndex));

      if (paymentResponse.ResponseCode === "0") {
        const MerchantRequestID = paymentResponse.MerchantRequestID;

        const checkPaymentStatus = async () => {
          const formdata = new FormData();
          formdata.append("MerchantRequestID", MerchantRequestID);

          const confirmResponse = await postDataToAPI(
            "/mpesa/confirmMpesaPayment.php",
            formdata
          );

          if (confirmResponse.status === "Success") {
            Swal.fire({
              title: "Payment Successful!",
              text: "Payment completed. Proceeding with registration...",
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            setIsPending(false);
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            onClose();
            onSuccess();
          } else if (confirmResponse.status === "Failed") {
            setIsPending(false);
            Swal.fire({
              icon: "error",
              title: "Payment Failed",
              text: "Payment Failed, try again later.",
            });
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            onClose();
          } else if (confirmResponse.status === "Pending") {
            setIsPending(true);
          } else {
            setIsPending(false);
            Swal.fire({
              icon: "error",
              title: "Payment Failed",
              text: "Payment could not be confirmed. Please try again.",
            });
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            onClose();
          }
        };

        intervalId = setInterval(checkPaymentStatus, 5000);

        timeoutId = setTimeout(() => {
          clearInterval(intervalId);
          setIsPending(false);
          Swal.fire({
            icon: "error",
            title: "Payment Failed",
            text: "Payment confirmation timed out. Please try again.",
          });
          onClose();
        }, 60000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: "Payment could not be processed.",
        });
        onClose();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while processing payment. Please try again.",
      });
      onClose();
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 10000);
    }
  };

  const handlePayment = () => {
    if (!paymentPhoneNumber) {
      setErrorMessage("Please provide your phone number for payment.");
      return;
    }

    const phoneNumberLength = paymentPhoneNumber.trim().length;
    if (phoneNumberLength !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    let cleanedPhoneNumber = paymentPhoneNumber.trim();
    if (cleanedPhoneNumber.startsWith("0")) {
      cleanedPhoneNumber = cleanedPhoneNumber.substring(1);
    }

    const prefixedPhoneNumber = `254${cleanedPhoneNumber}`;

    if (!amountPayable) {
      setErrorMessage("Amount payable not available.");
      return;
    }

    onPaymentSubmit(prefixedPhoneNumber, amountPayable);
  };

  return (
    <Modal open={isOpen} onClose={(e) => e.stopPropagation()}>
      <div className="modalsContents">
        <h3 style={{ fontWeight: "bold" }}>Complete Mpesa Payment</h3>

        {amountPayable !== null ? (
          <>
            <p>
              You are required to pay <b> KES {amountPayable}</b> via M-Pesa to
              continue.
            </p>

            <TextField
              type="tel"
              label="Phone Number e.g 0791638771"
              value={paymentPhoneNumber}
              onChange={handlePhoneNumberChange}
              fullWidth
              required
            />

            {errorMessage && (
              <p
                className="error-text"
                style={{ color: "red", marginTop: "10px" }}
              >
                {errorMessage}
              </p>
            )}

            {isPending && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="success" />
                <Typography variant="body2" style={{ marginTop: "10px" }}>
                  Awaiting Payment Confirmation...
                </Typography>
              </div>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handlePayment}
              style={{ marginTop: "20px" }}
              disabled={isButtonDisabled}
            >
              Make Payment
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              style={{
                marginTop: "20px",
                marginLeft: "10px",
                backgroundColor: "black",
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <p>Loading payment details...</p>
        )}
      </div>
    </Modal>
  );
};

export default MpesaPaymentModal;
