import React from "react";
import "../CSS/terms.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-container">

      <div className="terms-header">
        <h3>Privacy Policy for Nannies Desk</h3>
        <p>Effective Date: [1/12/2024]</p>
      </div>

      <div className="terms-content">
        <p>
          At Nannies Desk, your privacy is important to us. This Privacy Policy
          outlines how we collect, use, and protect your personal information in
          compliance with the applicable laws of Kenya, including the Data
          Protection Act, 2019. By using our website, services, or mobile
          application, you agree to the practices outlined in this policy.
        </p>

        <h4>1. Information We Collect</h4>
        <p>
          <strong>1.1 Personal Information:</strong>
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Mailing address</li>
          <li>
            Any other details necessary to provide or improve our services
          </li>
        </ul>
        <p>
          <strong>1.2 Non-Personal Information:</strong> We may collect
          non-personal data, such as browser type, IP address, and information
          about how you interact with our website or app, to help us optimize
          the user experience.
        </p>

        <h4>2. When We Collect Information</h4>
        <ul>
          <li>When you register or create an account with us</li>
          <li>When you fill out a contact form or sign up for a newsletter</li>
          <li>When you use our services or browse our website</li>
          <li>When you contact us for support or inquiries</li>
        </ul>

        <h4>3. How We Use Your Information</h4>
        <ul>
          <li>To personalize and improve your experience with our services</li>
          <li>To provide better customer support</li>
          <li>To process transactions and service requests efficiently</li>
          <li>
            To send periodic emails or notifications about updates, offers, and
            other relevant content
          </li>
          <li>
            To enhance the security and performance of our website and services
          </li>
        </ul>

        <h4>4. How We Protect Your Information</h4>
        <ul>
          <li>
            Use Secure Socket Layer (SSL) technology to encrypt sensitive data
          </li>
          <li>
            Limit access to personal information to authorized personnel who are
            required to maintain its confidentiality
          </li>
          <li>
            Regularly scan our website for vulnerabilities and use advanced
            security measures to protect your data
          </li>
          <li>
            All payment transactions are processed through a third-party gateway
            provider and are not stored or processed on our servers
          </li>
        </ul>

        <h4>5. Use of Cookies</h4>
        <p>
          Cookies are small files that help us improve your experience by
          tracking your activity on our site. We use cookies to:
        </p>
        <ul>
          <li>Save your preferences for future visits</li>
          <li>
            Analyze site traffic and user interaction to enhance our offerings
          </li>
        </ul>
        <p>
          You can choose to disable cookies through your browser settings.
          However, doing so may limit certain functionalities of the website.
        </p>

        <h4>6. Sharing Your Information</h4>
        <p>
          We do not sell, trade, or transfer your personally identifiable
          information to third parties unless necessary to:
        </p>
        <ul>
          <li>Comply with legal obligations</li>
          <li>
            Provide services through trusted partners who adhere to
            confidentiality agreements
          </li>
        </ul>
        <p>
          Non-personal data may be shared for analytics, advertising, or other
          legitimate uses.
        </p>

        <h4>7. Third-Party Links</h4>
        <p>
          Our website may include links to third-party websites or services.
          These external sites have their own privacy policies, and we are not
          responsible for their content or practices. We encourage you to review
          their policies before interacting with them.
        </p>

        <h4>8. Kenya’s Data Protection Act Compliance</h4>
        <ul>
          <li>
            You have the right to access, correct, or delete your personal data
          </li>
          <li>
            We will notify users of any changes to this privacy policy via email
          </li>
          <li>Our privacy policy is easily accessible on our website</li>
        </ul>

        <h4>9. Do Not Track Signals</h4>
        <p>
          We honor Do Not Track (DNT) signals and do not engage in any form of
          behavioral tracking when DNT is enabled in your browser settings.
        </p>

        <h4>10. Third-Party Behavioral Tracking</h4>
        <p>
          We do not allow third-party behavioral tracking on our website or app.
        </p>

        <h4>11. Data Breach Response</h4>
        <ul>
          <li>Notify affected users via email within 7 business days</li>
          <li>
            Take immediate steps to mitigate the breach and prevent future
            occurrences
          </li>
        </ul>

        <h4>12. Your Rights</h4>
        <ul>
          <li>Request access to your personal data</li>
          <li>Request that we update or delete your personal data</li>
          <li>
            Complain to the Office of the Data Protection Commissioner (ODPC) if
            your rights under the Data Protection Act, 2019 have been violated
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at info@nanniesdesk.com.
        </p>

        <h4>13. Changes to This Privacy Policy</h4>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be communicated via email and updated on this
          page.
        </p>

        <h4>14. Contact Us</h4>
        <p>
          For inquiries about this Privacy Policy or how your data is handled,
          contact us:
        </p>
        <ul>
          <li>Email: info@nanniesdesk.com</li>
          <li>Phone: +254722525689</li>
        </ul>
        <p>
          By using our services, you consent to this Privacy Policy. Thank you
          for trusting Nannies Desk with your personal information.
        </p>
      </div>

      <div className="terms-footer">
        <Button
          style={{
            backgroundColor: "#DE1877",
            color: "white",
            borderRadius: "40px",
            padding: "8px 32px",
          }}
          variant="contained"
          onClick={() => navigate("/")}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
