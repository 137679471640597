import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getUserLocation,
  handleGeolocationError,
  postDataToAPI,
  postFileDataToAPI,
  setLocalStorageVariable,
} from "../Shared/shared";
import { countryCodesList, homeOwner, tribes } from "../Shared/sharedLists";
import "../CSS/Login.css";
import logo from "../dashboardTemplate/assets/brand/logo2.png";
import nannyImage from "../LandingPage/components/bg/nanny.jpeg";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material";

const StyledInputField = styled(TextField)({
  width: "100%",
  marginBottom: "32px",
  "& label": {
    fontFamily: "inherit",
    color: "#6F7070",
  },
  "& label.Mui-focused": {
    color: "#DE1877 !important",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "inherit",
    borderRadius: "4px",
    "&.Mui-focused fieldset": {
      borderColor: "#642D92",
    },
  },
});

const Register = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Next Step");
  const [fullName, setFullName] = useState(
    localStorage.getItem("fullName") || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem("phoneNumber") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [confirmPassword, setConfirmPassword] = useState(
    localStorage.getItem("confirmPassword") || ""
  );
  const [registrationType, setRegistrationType] = useState(
    localStorage.getItem("registrationType") || ""
  );
  const [verificationCode, setVerificationCode] = useState("");
  const [step, setStep] = useState(1); // Step 1: Registration form, Step 2: Code verification form
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(
    localStorage.getItem("selectedCountry") || ""
  );
  const [termsAccepted, setTermsAccepted] = useState(
    localStorage.getItem("termsAccepted") === "true"
  );
  const [dateOfBirth, setDateOfBirth] = useState(() => {
    localStorage.getItem("dateOfBirth");
  });
  const [formattedDateOfBirth, setFormattedDateOfBirth] = useState("");
  const [address, setAddress] = useState(localStorage.getItem("address") || "");
  const [idNumber, setIdNumber] = useState(
    localStorage.getItem("idNumber") || ""
  );
  const [selectedImage, setSelectedImage] = useState(
    localStorage.getItem("selectedImage") || null
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [religion, setReligin] = useState(
    localStorage.getItem("religion") || ""
  );
  const [selectedPlaceToReceiveCode, setSelectedPlaceToReceiveCode] = useState(
    localStorage.getItem("selectedPlaceToReceiveCode") || ""
  );
  const [sex, setSex] = useState(localStorage.getItem("sex") || "");
  const [employeeRates, setEmployeeRates] = useState(
    localStorage.getItem("employeeRates") || ""
  );
  const [employerRates, setEmployerRates] = useState(
    localStorage.getItem("employerRates") || ""
  );
  const [selectedTribe, setSelectedTribe] = useState(
    localStorage.getItem("selectedTribe") || ""
  );
  // Calculate today's date minus 18 years
  const today = new Date();
  const minDate = new Date(today.setFullYear(today.getFullYear() - 18));
  // Format the date to YYYY-MM-DD format
  const minDateString = minDate.toISOString().split("T")[0];

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

  // Check screen size on load and resize
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();

  const generateVerificationCode = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result;
        setSelectedImage(base64Image); // Store Base64 string in state
        localStorage.setItem("selectedImage", base64Image); // Save to localStorage
      };
      reader.readAsDataURL(file); // Convert image to Base64 string
    }
  };

  useEffect(() => {
    // Cleanup: Revoke object URLs if previously used (not necessary for Base64)
    return () => {
      if (selectedImage && selectedImage.startsWith("blob:")) {
        URL.revokeObjectURL(selectedImage);
      }
    };
  }, [selectedImage]);

  // Handle profile picture change
  const handleProfilePictureUpdate = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("profilePicture", file);
      formData.append("userEmail", email);

      const response = await postFileDataToAPI(
        "/dashboard/updateProfilePicture.php",
        formData
      );

      if (response === "Success") {
        return true;
        console.log("Success...");
      } else {
        return false;
      }
    }
  };

  const handleNextStep = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (
      !fullName ||
      !phoneNumber ||
      !email ||
      !selectedCountry ||
      !password ||
      !registrationType ||
      !dateOfBirth ||
      !address ||
      !selectedTribe ||
      !sex ||
      !religion ||
      !idNumber
    ) {
      setErrorMessage("All fields are required.");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    const phonePattern = /^\+[1-9]\d{1,14}$/; // E.164 format
    // Check if phoneNumber starts with '0'
    if (phoneNumber.startsWith("0")) {
      setErrorMessage("Phone number cannot start with zero.");
      return;
    }

    console.log("Full Phone: ", `${selectedCountryCode}${phoneNumber}`);
    // Validate the full phone number format
    if (!phonePattern.test(`${selectedCountryCode}${phoneNumber}`)) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }

    //Check If Email / Phone number / Id Number Already taken
    const formDataa = new FormData();
    formDataa.append("email", email);
    formDataa.append("phoneNumber", `${selectedCountryCode}${phoneNumber}`);

    const recordsAlreadyExist = await postDataToAPI(
      "/Auth/checkIfRecordsAlreadyTaken.php",
      formDataa
    );
    if (recordsAlreadyExist !== "success") {
      setErrorMessage(recordsAlreadyExist);
      return;
    }

    //Disable button Temporarily
    handleClick();
    const code = generateVerificationCode();
    setLocalStorageVariable("verificationCode", code);

    let response;
    if (selectedPlaceToReceiveCode === "email") {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("code", code);

      response = await postDataToAPI(
        "/Emails/generateEmailVerificationCode.php",
        formData
      );
      if (response === "Code Sent") {
        setStep(2);
        setErrorMessage("");
      } else {
        setErrorMessage("Failed to send verification code. Please try again.");
      }
    } else if (selectedPlaceToReceiveCode === "phone") {
      const formData = new FormData();
      const recipients = `${selectedCountryCode}${phoneNumber}`.replace(
        /^\+/,
        ""
      );
      console.log("PhoneNumber: ", recipients);
      formData.append("recipients", recipients);
      formData.append("phone", phoneNumber);
      const message = "Hello, Your Nannies Desk Verification Code is: " + code;
      formData.append("message", message);
      formData.append("from", "NanniesDesk Registration Server");

      response = await postDataToAPI("/sms/sendSMS.php", formData);

      if (response.status === "Success") {
        setStep(2);
        setErrorMessage("");
      } else {
        setErrorMessage("Failed to send verification code. Please try again.");
      }
    }
  };
  // Save each field to localStorage on change
  useEffect(() => {
    localStorage.setItem("fullName", fullName);
    localStorage.setItem("phoneNumber", phoneNumber);
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
    localStorage.setItem("confirmPassword", confirmPassword);
    localStorage.setItem("registrationType", registrationType);
    localStorage.setItem("selectedCountry", selectedCountry);
    localStorage.setItem("termsAccepted", termsAccepted);
    localStorage.setItem("address", address);
    localStorage.setItem("selectedTribe", selectedTribe);
    localStorage.setItem("employerRates", employerRates);
    localStorage.setItem("employeeRates", employeeRates);
    localStorage.setItem("sex", sex);
    localStorage.setItem("religion", religion);
    localStorage.setItem(
      "selectedPlaceToReceiveCode",
      selectedPlaceToReceiveCode
    );
    localStorage.setItem("idNumber", idNumber);
  }, [
    fullName,
    phoneNumber,
    email,
    password,
    confirmPassword,
    registrationType,
    selectedCountry,
    termsAccepted,
    address,
    selectedTribe,
    employerRates,
    employeeRates,
    sex,
    religion,
    idNumber,
    selectedPlaceToReceiveCode,
  ]);

  // Clear localStorage after successful registration
  const clearFormStorage = () => {
    localStorage.removeItem("fullName");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("confirmPassword");
    localStorage.removeItem("registrationType");
    localStorage.removeItem("selectedCountry");
    localStorage.removeItem("termsAccepted");
    localStorage.removeItem("dateOfBirth");
    localStorage.removeItem("address");
    localStorage.removeItem("selectedTribe");
    localStorage.removeItem("employeeRates");
    localStorage.removeItem("employerRates");
    localStorage.removeItem("sex");
    localStorage.removeItem("religion");
    localStorage.removeItem("selectedPlaceToReceiveCode");
    localStorage.removeItem("idNumber");
    localStorage.removeItem("selectedImage");
  };

  const completeRegistration = async () => {
    try {
      // Default coordinates (fallback)
      let latitude = -1.286389;
      let longitude = 36.817223;

      try {
        // Try to get the user's location
        const location = await getUserLocation();
        latitude = location.latitude;
        longitude = location.longitude;
      } catch (error) {
        // In case of an error (e.g., denied location permission), fallback to default coordinates
        console.error(
          "Location fetch failed, using fallback coordinates.",
          error
        );
        handleGeolocationError(error); // Show appropriate error message to the user
      }

      // Prepare the form data for registration
      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("dateOfBirth", dateOfBirth);
      formData.append("phoneNumber", `${selectedCountryCode}${phoneNumber}`);
      formData.append("email", email);
      formData.append("selectedCountry", selectedCountry);
      formData.append("password", password);
      formData.append("address", address);
      formData.append("coordinates", JSON.stringify([latitude, longitude]));
      formData.append("registrationType", registrationType);
      formData.append("tribe", selectedTribe);
      formData.append("employerRates", employerRates);
      formData.append("employeeRates", employeeRates);
      formData.append("sex", sex);
      formData.append("religion", religion);
      formData.append("idNumber", idNumber);

      console.log("fullName: ", fullName);
      console.log("dateOfBirth: ", dateOfBirth);
      console.log("phoneNumber: ", `${selectedCountryCode}${phoneNumber}`);
      console.log("email: ", email);
      console.log("selectedCountry: ", selectedCountry);
      console.log("address: ", address);
      console.log("coordinates: ", JSON.stringify([latitude, longitude]));
      console.log("registrationType: ", registrationType);
      console.log("selectedTribe: ", selectedTribe);
      console.log("employerRates: ", employerRates);
      console.log("employeeRates: ", employeeRates);
      console.log("sex: ", sex);
      console.log("religion: ", religion);
      console.log("idNumber: ", idNumber);

      // Submit the registration form
      const response = await postDataToAPI("/Auth/signUp.php", formData);
      if (response === "success") {
        let profileImageUpdated = await handleProfilePictureUpdate(
          selectedFile
        );
        if (profileImageUpdated) {
          clearFormStorage();
          Swal.fire({
            title: "Success!",
            text: "Registration successful! Redirecting to Login Page",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
            willClose: () => {
              navigate("/login");
            },
          });
        }
      } else if (response === "Email Already Taken") {
        setErrorMessage("Email already taken.");
      } else {
        console.log("RES: ", response);
        setErrorMessage("Registration failed, please try again later.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();

    if (!verificationCode) {
      setErrorMessage("Verification code is required.");
      return;
    }

    const savedCode = localStorage.getItem("verificationCode");
    if (savedCode !== verificationCode) {
      setErrorMessage("Verification code is incorrect.");
      return;
    }

    completeRegistration();
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleClick = () => {
    // Disable the button and change text
    setIsDisabled(true);
    setButtonText("Sending verification code");

    // Re-enable button and reset text after 10 seconds
    setTimeout(() => {
      setIsDisabled(false);
      setButtonText("Next Step");
    }, 50000);
  };

  return (
    <>
      <div className={"login-section-container"}>
        <div
          className={"login-left-container"}
          style={{ position: "sticky", top: "0" }}
        >
          <img src={logo} alt={"Logo"} className={"login-logo-image"} />
          <h3 className={"login-left-title"}>
            Trusted Caregiver For Your Little Ones
          </h3>
          <p className={"logo-description"}>
            We have so many satisfied parents who are really happy to take our
            services
          </p>
          <div className={"employer-details"}>
            <p className={"employer-details-text"}>
              “Caregivers are the unsung heroes who pour their hearts into
              nurturing, guiding, and shaping the futures of the little ones
              they embrace”
            </p>
            <div className={"employer-image-container"}>
              <img src={nannyImage} alt={"Employer Image"} />
              <div className={"employer-name"}>
                <p>Veres Panna</p>
                <p>Employer</p>
              </div>
            </div>
          </div>
        </div>
        <div className={"login-right-container"}>
          {step === 1 ? (
            <div className={"register-form-container"}>
              <h3>Register</h3>
              <p className={"register-help-text"}>
                Kindly provide the following details to register an account
              </p>
              <div
                style={{
                  height: isLargeScreen ? "90vh" : "auto",
                  overflowY: isLargeScreen ? "auto" : "visible",
                }}
              >
                <form onSubmit={handleNextStep} style={{ paddingTop: "32px" }}>
                  <StyledInputField
                    required
                    select
                    id={"role-select"}
                    label={"Select who to register as"}
                    slotProps={{
                      select: {
                        native: true,
                      },
                    }}
                    value={registrationType}
                    onChange={(e) => setRegistrationType(e.target.value)}
                  >
                    <option value="">Select who to register as</option>
                    <option value="house-help">House Help (Employee)</option>
                    <option value="home-owner">Home Owner (Employer)</option>
                    <option value="chef">Chef (Employee)</option>
                    <option value="gardener">Gardener (Employee)</option>
                  </StyledInputField>
                  <StyledInputField
                    required
                    id="outlined-full-name"
                    label="Enter Full Name as per Id"
                    placeholder="Eg. John Kore Doe "
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                    }}
                    type={"text"}
                  />
                  <div style={{ marginBottom: "10px" }}>
                    <label
                      htmlFor="outlined-dob"
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        textAlign: "left",
                      }}
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      id="outlined-dob"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      min={dayjs().subtract(100, "years").format("YYYY-MM-DD")}
                      max={dayjs().subtract(18, "years").format("YYYY-MM-DD")}
                      required
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid grey",
                        borderRadius: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        outline: "none",
                      }}
                    />
                  </div>
                  <StyledInputField
                    required
                    id="outlined-required"
                    label="Enter Email"
                    placeholder="Eg. example@gmail.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type={"email"}
                  />
                  <StyledInputField
                    required
                    select
                    id={"country-code-select"}
                    label={""}
                    slotProps={{
                      select: {
                        native: true,
                      },
                    }}
                    value={selectedCountryCode}
                    onChange={(e) => {
                      setSelectedCountryCode(e.target.value);
                      setSelectedCountry(
                        e.target.options[e.target.selectedIndex].text
                      );
                    }}
                  >
                    <option value="">Select your country code</option>
                    {countryCodesList.map((option) => (
                      <option key={option.code} value={option.code}>
                        {option.code} - {option.label}
                      </option>
                    ))}
                  </StyledInputField>
                  <StyledInputField
                    required
                    id="outlined-phone-number"
                    label="Enter Phone Number"
                    placeholder="Eg. 712345678"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    type={"tel"}
                  />
                  <StyledInputField
                    required
                    id="outlined-address"
                    label="Enter Nearest Town"
                    placeholder="Eg. Nairobi"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    type={"text"}
                  />
                  {registrationType !== homeOwner && (
                    <StyledInputField
                      required
                      id="outlined-employee-rates"
                      label="Enter Monthly Rates(pay)"
                      placeholder="Eg. 20000"
                      value={employeeRates}
                      onChange={(e) => {
                        setEmployeeRates(e.target.value);
                      }}
                      type={"number"}
                    />
                  )}
                  {registrationType === homeOwner && (
                    <StyledInputField
                      required
                      id="outlined-employer-rates"
                      label="Enter your monthly budget"
                      placeholder="Eg. 20000"
                      value={employerRates}
                      onChange={(e) => {
                        setEmployerRates(e.target.value);
                      }}
                      type={"number"}
                    />
                  )}
                  <StyledInputField
                    required
                    id="outlined-national-id"
                    label="Enter National ID Number"
                    placeholder="Eg. 12345678"
                    value={idNumber}
                    onChange={(e) => {
                      setIdNumber(e.target.value);
                    }}
                    type={"text"}
                  />
                  <StyledInputField
                    required
                    select
                    id={"sex-select"}
                    label={""}
                    slotProps={{
                      select: {
                        native: true,
                      },
                    }}
                    value={sex}
                    onChange={(e) => setSex(e.target.value)}
                  >
                    <option value="">Select your Sex</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </StyledInputField>
                  <StyledInputField
                    required
                    select
                    id={"religion-select"}
                    label={""}
                    slotProps={{
                      select: {
                        native: true,
                      },
                    }}
                    value={religion}
                    onChange={(e) => setReligin(e.target.value)}
                  >
                    <option value="">Religion</option>
                    <option value="Christian">Christian</option>
                    <option value="Muslim">Muslim</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Other">Other</option>
                  </StyledInputField>
                  <StyledInputField
                    required
                    select
                    id={"tribe-select"}
                    label={""}
                    slotProps={{
                      select: {
                        native: true,
                      },
                    }}
                    value={selectedTribe}
                    onChange={(e) => setSelectedTribe(e.target.value)}
                  >
                    <option value="">Select your tribe</option>
                    {tribes.kenyan_tribes.map((tribe) => (
                      <option key={tribe} value={tribe}>
                        {tribe}
                      </option>
                    ))}
                  </StyledInputField>
                  <StyledInputField
                    required
                    id="outlined-password"
                    label="Create Password"
                    placeholder="Eg. 765782"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type={"password"}
                  />
                  <StyledInputField
                    required
                    id="outlined-confirm-password"
                    label="Confirm Password"
                    placeholder="Eg. 765782"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    type={"password"}
                  />
                  <StyledInputField
                    required
                    select
                    id={"code-select"}
                    label={""}
                    slotProps={{
                      select: {
                        native: true,
                      },
                    }}
                    value={selectedPlaceToReceiveCode}
                    onChange={(e) =>
                      setSelectedPlaceToReceiveCode(e.target.value)
                    }
                  >
                    <option value="">Select Where to receive code</option>
                    <option value="email">On your Email</option>
                    <option value="phone">Phone Via Text</option>
                  </StyledInputField>
                  {/* <div className={"select-photo-container"}>
                    <label
                      htmlFor="outlined-file"
                      style={{
                        padding: "8px 12px",
                        backgroundColor: "#642D92",
                        color: "#fff",
                        borderRadius: "4px",
                        cursor: "pointer",
                        display: "inline-block",
                      }}
                    >
                      {selectedImage ? "Change Profile Photo" : "Select Profile Photo"}
                    </label>
                    <input
                      required
                      id="outlined-file"
                      accept="image/*"
                      onChange={handleProfilePictureChange}
                      type="file"
                      style={{
                        display: "none",
                      }}
                    />
                  </div> */}
                  <div className="" style={{ textAlign: "left" }}>
                    <label>Select Profile Photo:</label>
                    <br />
                    <input
                      required
                      type="file"
                      accept="image/*"
                      onChange={handleProfilePictureChange}
                      className="upload-button"
                      style={{
                        width: "60%",
                        marginRight: "10px",
                        marginTop: "16px",
                        marginBottom: "16px",
                      }}
                    />
                    {selectedImage && (
                      <div style={{ marginTop: "10px" }}>
                        <p>Selected Image:</p>
                        <img
                          src={selectedImage} // Use Base64 or object URL
                          alt="Selected"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                  </div>{" "}
                  <br></br>
                  <div
                    className="formGroup"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      required
                    />
                    <span style={{ marginLeft: "8px" }}>
                      I accept the <Link to="/terms">Terms and Services</Link>
                    </span>
                  </div>
                  {/* Error and Success Messages */}
                  {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                  )}
                  {successMessage && (
                    <p className="success-message">{successMessage}</p>
                  )}
                 {/* Submit Button */}
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: isDisabled ? "#aaa" : "#DE1877", // Lighter color when disabled
                    color: "white",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "50px",
                    cursor: isDisabled ? "not-allowed" : "pointer",
                    fontSize: "16px",
                  }}
                  disabled={isDisabled}
                >
                  {buttonText}
                </button>
                  {/* Login Link */}
                  <div style={{ color: "green", marginTop: "10px" }}>
                    <Link to="/login" className="link-register" id="link">
                      Already have an Account? Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className={"code-submission-container"}>
              <h3>Register</h3>
              <form
                onSubmit={handleCodeSubmit}
                className={"code-submission-form"}
              >
                <p
                  className="register-title"
                  style={{ textAlign: "left", marginBottom: "20px" }}
                >
                  {selectedPlaceToReceiveCode === "email" ? (
                    <p>Check your email for the verification code</p>
                  ) : (
                    <p>Check your phone for the verification code</p>
                  )}
                </p>
                <div className={"verify-code-image"} />
                <StyledInputField
                  required
                  id="outlined-verification-code"
                  label="Verification Code"
                  placeholder="Enter Verification Code"
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificationCode(e.target.value);
                  }}
                  type={"text"}
                />
                {errorMessage && (
                  <p
                    className="error-message"
                    style={{ color: "red", textAlign: "center" }}
                  >
                    {errorMessage}
                  </p>
                )}
                <button type={"submit"} className={"login-form-button"}>
                  {selectedPlaceToReceiveCode === "email" ? (
                    <div>Verify Email</div>
                  ) : (
                    <div>Verify Phone</div>
                  )}
                </button>
                <div
                  className="login-links"
                  style={{ textAlign: "center", marginTop: "15px" }}
                >
                  <Link
                    to="/login"
                    className="link-register"
                    style={{ textDecoration: "none", color: "#007BFF" }}
                  >
                    Already have an Account? Login
                  </Link>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
