import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";
import '../CSS/Login.css';
import logo from '../dashboardTemplate/assets/brand/logo2.png';
import nannyImage from '../LandingPage/components/bg/nanny.jpeg';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material";

const StyledInputField = styled(TextField)({
  width: '100%',
  marginBottom: '32px',
  '& label': {
    fontFamily: 'inherit',
    color: '#6F7070'
  },
  '& label.Mui-focused': {
    color: '#DE1877 !important'
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'inherit',
    borderRadius: '4px',
    '&.Mui-focused fieldset': {
      borderColor: '#642D92'
    }
  }
});

const ResetPassword = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Send Reset Code");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1); // Step 1: Email input, Step 2: Code and password input
  const [emailValid, setEmailValid] = useState(true); // Email validation state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  const handleClick = () => {
    // Disable the button and change text
    setIsDisabled(true);
    setButtonText("Sending Reset code");

    // Re-enable button and reset text after 10 seconds
    setTimeout(() => {
      setIsDisabled(false);
      setButtonText("Next Step");
    }, 50000);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailValid(false);
      return;
    }
    setEmailValid(true);

    //Temporarity disable
    handleClick();
    // Simulate sending a reset code to the email
    const resetCode = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem("verificationCode", resetCode);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("code", resetCode);

    const response = await postDataToAPI(
      "/Emails/generateEmailVerificationCode.php",
      formData
    );
    if (response === "Code Sent") {
      setStep(2);
      setErrorMessage("");
    } else {
      console.log("Res: ", response);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    const storedCode = localStorage.getItem("verificationCode");
    setErrorMessage("");
    setSuccessMessage("");

    if (code === storedCode) {
      if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords do not match. Please try again.");
        return;
      }
      // Reset password logic here
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", newPassword);

      const response = await postDataToAPI("/Auth/resetPassword.php", formData);

      if (response === "Success") {
        Swal.fire({
          title: "Success!",
          text: "Password has been reset successfully! You will get redirected to login page",
          icon: "success",
          timer: 3000, // 3 seconds
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        });
        setEmail("");
        setCode("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } else {
      setErrorMessage("Invalid reset code. Please try again.");
    }
  };

  return (
    <div className="login-section-container">
      <div className={"login-left-container"}>
        <img src={logo} alt={"Logo"} className={"login-logo-image"} />
        <h3 className={"login-left-title"}>Trusted Caregiver For Your Little Ones</h3>
        <p className={"logo-description"}>We have so many satisfied parents who are really happy to take our services</p>
        <div className={"employer-details"}>
          <p className={"employer-details-text"}>“Caregivers are the unsung heroes who pour their hearts into nurturing, guiding, and shaping the futures of the little ones they embrace”</p>
          <div className={"employer-image-container"}>
            <img src={nannyImage} alt={"Employer Image"} />
            <div className={"employer-name"}>
              <p>Veres Panna</p>
              <p>Employer</p>
            </div>
          </div>
        </div>
      </div>
      <div className={"login-right-container"}>
        {step === 1 ? (
          <>
            <lottie-player
              src="Assets/78126.json"
              background="transparent"
              speed="1"
              style={{
                width: "180px",
                height: "180px",
              }}
              loop
              autoplay
            ></lottie-player>
            <p className={"login-help-text"}>Kindly provide the following details to reset your account password</p>
            <form onSubmit={handleEmailSubmit}>
              <StyledInputField
                required
                id="outlined-required"
                label="Enter Email"
                placeholder="Eg. example@gmail.com"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
                type={"email"}
              />
              {!emailValid && (
                <p className="email-invalid-error-message">
                  Please enter a valid email address.
                </p>
              )}
              <button
                type={"submit"}
                className={"reset-form-button"}
                disabled={isDisabled}
                style={{
                  backgroundColor: isDisabled ? "#aaa !important" : "",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}>
                {buttonText}
              </button>
            </form>
            <div className={"login-bottom-section"}>
              <Link to="/register" style={{ color: "green" }} id="link">
                Create an account
              </Link>
              <Link to="/login" className="link-resetPassword" id="link">
                Login
              </Link>
            </div>
          </>
        ) : <>
          <h3 className={"reset-password-title"}>Reset Password</h3>
          <form onSubmit={handleCodeSubmit}>
            <StyledInputField
              required
              id="outlined-required"
              label="Enter code sent to your email"
              placeholder="Eg. 1234567"
              value={code}
              onChange={(e) => { setCode(e.target.value) }}
              type={"text"}
            />
            <StyledInputField
              required
              id="outlined-required"
              label="Enter new password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => { setNewPassword(e.target.value) }}
              type={"password"}
            />
            <StyledInputField
              required
              id="outlined-required"
              label="Confirm new password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => { setConfirmPassword(e.target.value) }}
              type={"password"}
            />
            {errorMessage && (
              <p className="reset-error-message">{errorMessage}</p>
            )}
            {successMessage && (
              <p className="reset-success-message">{successMessage}</p>
            )}
            <button
              type={"submit"}
              className={"reset-form-button"}
            >
              Reset Password
            </button>
          </form>
          <div className={"login-bottom-section"}>
            <Link to="/register" style={{ color: "green" }} id="link">
              Create an account
            </Link>
            <Link to="/login" className="link-resetPassword" id="link">
              Login
            </Link>
          </div>
        </>}
      </div>
    </div>
  );
};

export default ResetPassword;
