import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../dashboardTemplate/assets/brand/logo2.png";
import location from '../../dashboardTemplate/assets/images/location.png';
import email from '../../dashboardTemplate/assets/images/email.png';
import '../../CSS/NavBar.css';

export const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    if (window.innerWidth > 768) {
      setMenuOpen(false); // Close the menu if switching to desktop view
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    nav: {
      width: "100%",
    },
    containerFluid: {
      width: "100%",
      margin: "0",
      padding: "0",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "white",
      padding: "10px 0px",
      //flexWrap: isMobile ? "wrap" : "nowrap",
    },
    logo: {
      height: "40px",
    },
    buttons: {
      display: isMobile ? "flex" : "flex",
      justifyContent: isMobile ? "center" : "none",
      flexGrow: isMobile ? "1" : "0",
      textAlign: "right",
      alignItems: "center"
    },
    button: {
      margin: isMobile ? "0 5px" : "5px",
      padding: "5px 10px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      backgroundColor: "white",
    },
    loginButton: {
      backgroundColor: "white",
      color: "#DE1777",
      border: "1px solid #DE1777",
    },
    registerButton: {
      backgroundColor: "#DE1777",
      color: "white",
    },
    menuToggle: {
      fontSize: "24px",
      color: "white",
      backgroundColor: "#642D92",
      padding: "10px",
      cursor: "pointer",
      display: isMobile ? "block" : "none",
    },
    menuItems: {
      display: menuOpen ? "flex" : "none",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#642D92",
      width: "100%",
      padding: "10px 0",
    },
    menuItem: {
      color: "white",
      padding: "10px",
      textDecoration: "none",
    },
    desktopMenu: {
      display: isMobile ? "none" : "flex",
      justifyContent: "space-evenly",
      backgroundColor: "#642D92",
      padding: "10px 0",
    },
  };

  return (
    <>
      <nav className={"navbar-container"}>
        <div className={"navbar-container-inset"}>
          {/* Header */}
          <div className={"navbar-header"}>
            {/* Logo */}
            <img src={logo} alt="Logo" className={"navbar-logo"} />
            {/* Login and Register Buttons (Mobile and Desktop) */}
            <div className={"navbar-help-info-container"}>
              <div className={"info-item"}>
                <img src={location} height={"32px"} width={"32px"} alt={"location-icon"} />
                <div className={"info-item-text"}>
                  <p>Address:</p>
                  <p>Nairobi, Kenya</p>
                </div>
              </div>
              <div className={"separator"} />
              <div className={"info-item"}>
                <img src={email} height={"32px"} width={"32px"} alt={"location-icon"} />
                <div className={"info-item-text"}>
                  <p>Mail us:</p>
                  <p>info@nanniesdesk.com</p>
                </div>
              </div>
            </div>
            <div style={styles.buttons} className={"navbar-top-bar-links"}>
              <Link to="/login">
                <button className={"login-button"}>
                  LOGIN
                </button>
              </Link>
              <Link to="/register">
                <button className={"register-button"}>
                  REGISTER
                </button>
              </Link>
            </div>

            {/* Menu Toggle (Mobile) */}
            <div style={styles.menuToggle} onClick={toggleMenu}>
              <i className={`fa ${menuOpen ? "fa-times" : "fa-bars"}`}></i>
            </div>
          </div>
        </div>
      </nav>
      <div className={"navbar-links-container"} style={isMobile ? styles.menuItems : {}}>
        <div className={"navbar-links"}>
          <div>
            <a href="#aboutus">
              About Us
            </a>
            <span>/</span>
            <a href="#aboutus">
              Featured Caregiver
            </a>
            <span>/</span>
            <a href="#servicess">
              Key Features
            </a>
            <span>/</span>
            <a href="#blog">
              Blog
            </a>
            <span>/</span>
            <a href="#team">
              Team
            </a>
            <span>/</span>
            <a href="#contact">
              Contact
            </a>
          </div>
          <button type={"button"} onClick={() => console.log("Post A Job")} className={"post-job-button"}>
            Hire Nanny
          </button>
        </div>
      </div>
    </>
  );
};
