import React from "react";
import '../../CSS/Blog.css';

export const About = (props) => {
  return (
    <div id="blog" className={"blog-section"}>
      <div className={"blog-section-inner"}>
        <div className="blog-section-left">
          <h3 className={"blog-section-title"}>Blog</h3>
          <p className={"blog-section-description"}>
            “Caregivers are the unsung heroes who pour their hearts into
            nurturing, guiding, and shaping the futures of the little ones
            they embrace.With each tender moments.”
          </p>
          <hr />
          <div className={"quote-section"}>
            <p className="quote">''</p>
            <div className={"quote-details"}>
              <p>Mark Henrique</p>
              <p>Senior Caregiver</p>
            </div>
          </div>
        </div>
        <div className={"blog-section-right"}>
          <img src={"img/about.jpg"} alt={"Video"} />
          {/* <div className={"play-button"}>
            <i className={"bi bi-play-fill"}></i>
          </div> */}
        </div>
      </div>
    </div>
  );
};
