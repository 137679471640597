import React, { useEffect, useState } from "react";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";
import { useNavigate } from "react-router-dom";

function MyJobApplications() {
  const userEmail = getLocalStorageVariable("userEmail");
  const navigate = useNavigate();

  const [myJobApplications, setMyJobApplications] = useState([]);
  const [myJobsFullDetails, setMyJobsFullDetails] = useState([]);
  // Fetch training records
  useEffect(() => {
    const fetchAppliedJobs = async () => {
      const formData = new FormData();
      formData.append("userEmail", userEmail);

      const response = await postDataToAPI(
        "/dashboard/fetchAppliedJobs.php",
        formData
      );

      if (response) {
        setMyJobApplications(response); // Set training records (id, email, trainingId, timestamp)
      }
    };

    fetchAppliedJobs();
  }, [userEmail]);

  // Fetch detailed information for each training
  useEffect(() => {
    if (myJobApplications.length === 0) {
      return;
    }

    const fetchJobDetails = async () => {
      const detailsPromises = myJobApplications.map(async (jobs) => {
        const formData = new FormData();
        formData.append("jobId", jobs.jobId);
        const response = await postDataToAPI(
          "/dashboard/fetchJobDetailsById.php",
          formData
        );

        return response;
      });

      const allDetails = await Promise.all(detailsPromises);
      setMyJobsFullDetails(allDetails.filter((detail) => detail));
    };

    fetchJobDetails();
  }, [myJobApplications]);

  const seeFullJobDetails = (jobId) => {
    navigate("/viewJob", { state: { jobId } });
  };

  return (
<div className={"employer-dashboard-page"}>
  <div>
    <div className="row">
      <div className="col-sm-12">
        <h3>Applied Jobs:</h3>
        {myJobsFullDetails.length > 0 ? (
          <div style={{ overflowX: 'auto', WebkitOverflowScrolling: 'touch' }}> {/* Inline CSS for scroll */}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Employment Type</th>
                  <th>Job Location</th>
                  <th>Advert Status</th>
                  <th>Application Status</th>
                  <th>Pay</th>
                  <th>Application Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {myJobsFullDetails.map((jobDetails, index) => (
                  <tr key={index}>
                    <td>{jobDetails[0]?.role || "N/A"}</td>
                    <td>{jobDetails[0]?.employmentType || "N/A"}</td>
                    <td>{jobDetails[0]?.jobLocation || "N/A"}</td>
                    <td>{jobDetails[0]?.status || "N/A"}</td>
                    <td>{myJobApplications[index]?.status || "N/A"}</td>
                    <td>{jobDetails[0]?.pay || "N/A"}</td>
                    <td>{myJobApplications[index]?.timeStamp || "N/A"}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => seeFullJobDetails(jobDetails[0]?.id)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No Applications Found.</p>
        )}
      </div>
    </div>
  </div>
</div>

  );
}

export default MyJobApplications;
