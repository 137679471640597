import React, { useEffect, useRef } from "react";
import landingPageVideo from "./bg/landingPageVideo.mp4";

export const Header = (props) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    if (window.bootstrap) {
      console.log("Bootstrap exists");

      if (carouselRef) {
        const myCarousel = new window.bootstrap.Carousel(carouselRef.current, {
          interval: 5000, //Alter this value to set the carousel duration
          wrap: true,
          touch: false,
          pause: false,
        });

        myCarousel.cycle();
      }
    } else {
      console.log("Whoops...it doesn't exist");
    }
  }, []);

  return (
    <div className="intro">
      <div class={"carousel slide header-carousel"} ref={carouselRef}>
        <div className={"carousel-inner"}>
          <div className="carousel-item active">
            <video
              className="d-block w-100"
              autoPlay
              loop
              muted
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source src={landingPageVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="overlay">
        <div className="overlay-content-container">
          <h1>
            Trusted Caregiver <br /> For Your Little
            <br /> Ones
          </h1>
          <p>
            We have so many satisfied parent who are really
            <br />
            happy to take our services
          </p>
          {/* <a
            href="#servicess"
            className="btn btn-custom btn-lg page-scroll"
          >
            Learn More
          </a> */}
        </div>
      </div>
    </div>
  );
};
