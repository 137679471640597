import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./Auth/Login";
import Terms from "./components/Terms";
import Register from "./Auth/Register";
import ResetPassword from "./Auth/ResetPassword";
import LandingPage from "./LandingPage/LandingPage";
import DefaultLayout from "./dashboardTemplate/layout/DefaultLayout";
import './dashboardTemplate/scss/style.scss'

import './dashboardTemplate/scss/examples.scss'
import Disclaimer from "./components/Disclaimer";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/Disclaimer" element={<Disclaimer />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
