// Component for the second image design
import { useRef, useEffect } from 'react';
import nannyImg from './bg/nanny.jpeg';
import '../../CSS/CaregiverFilter.css';

const featureCardArray = [
  {
    imgSrc: nannyImg,
    nannyName: 'Veres Panna',
    locationName: 'LA-NHC',
    rating: '5.0',
    tags: ["Baby Sitter", "Full Time"],
    rate: 'KES 250/h'
  },
  {
    imgSrc: nannyImg,
    nannyName: 'Veres Panna',
    locationName: 'LA-NHC',
    rating: '5.0',
    tags: ["Child Care", "Full Time"],
    rate: 'KES 220/h'
  },
  {
    imgSrc: nannyImg,
    nannyName: 'Veres Panna',
    locationName: 'LA-NHC',
    rating: '5.0',
    tags: ["New Born", "Full Time"],
    rate: 'KES 170/h'
  }
];

const topRatedContent = {
  imgSrc: nannyImg,
  nannyName: 'Veres Panna',
  speciality: 'Toddler Care',
  locationName: 'LA-NHC',
  rating: '5.0',
  rate: 'KES 250/h',
  description: 'I specialize in infant care, and my priority is ensuring your baby feels loved, safe and happy.',
  tags: ['Full Time', 'Infant Care']
};

const topRatedContentArray = Array.from({ length: 5 }, () => ({ ...topRatedContent }));

const FeaturedCaregiverCard = ({ data, key }) => {
  return (
    <div className={"caregiver-card"} key={key}>
      <div className={"caregiver-card-inner"}>
        <img src={data.imgSrc} alt={"Caregiver"} />
        <div className={"caregiver-card-content"}>
          <p className={"nanny-name"}>{data.nannyName}</p>
          <div className={"location"}>
            <i className={"bi bi-geo-alt"}></i>
            <p>{data.locationName}</p>
          </div>
        </div>
        <div className={"rating"}>
          <i className={"bi bi-star-fill"}></i>
          <p>({data.rating})</p>
        </div>
      </div>
      <div className={"tags-section"}>
        {data.tags.map((tag) => (
          <div className={"tag"}>
            {tag}
          </div>
        ))}
        <p className={"rate"}>{data.rate}</p>
      </div>
    </div>
  );
};

const TopRatedCaregiverCard = ({ data, key }) => {
  return (
    <div className={"carousel-cell top-rated-caregiver-card"} key={key}>
      <div className={"top-rated-caregiver-card-inner"}>
        <img src={data.imgSrc} alt={"Caregiver"} />
        <div className={"top-rated-caregiver-card-content"}>
          <p className={"nanny-name"}>{data.nannyName}</p>
          <p className={"nanny-speciality"}>{data.speciality}</p>
          <div className={"location"}>
            <i className={"bi bi-geo-alt"}></i>
            <p>{data.locationName}</p>
          </div>
          <p className={"top-rated-caregiver-rate"}>{data.rate}</p>
        </div>
        <div className={"rating"}>
          <i className={"bi bi-star-fill"}></i>
          <p>({data.rating})</p>
        </div>
      </div>
      <div className={"top-rated-caregiver-description"}>
        {data.description}
      </div>
      <div className={"top-rated-caregiver-tags"}>
        <div className={"top-rated-caregiver-tags-inner"}>
          {data.tags.map((tag) => (
            <div className={"tag"}>
              {tag}
            </div>
          ))}
        </div>
        <button type={"button"} className={"view-more-button"}>View Profile</button>
      </div>
    </div>
  );
};

// Component for the third image design
export const CaregiverFilter = () => {
  const flickityRef = useRef(null);

  useEffect(() => {
    if (window.Flickity) {
      const flickityInstance = new window.Flickity(flickityRef.current, {
        cellAlign: "center",
        contain: true,
        wrapAround: true,
        pageDots: true,
        autoPlay: 3000,
        prevNextButtons: false,
      });

      return () => {
        flickityInstance.destroy();
      };
    } else {
      console.error("Flickity is not loaded on the window object.");
    }
  }, []);

  return (
    <div className={"caregiver-section"}>
      <h3>Featured Caregiver</h3>
      <div className={"caregiver-section-inner"}>
        <div className={"caregiver-left-section"}>
          {featureCardArray.map((data, index) => (
            <FeaturedCaregiverCard data={data} key={index} />
          ))}
          <p className={"caregiver-left-section-bottom-title"}>2K+ Caregivers are available for you</p>
          <p className={"caregiver-left-section-bottom-text"}>You can search according to your choice and find the best one.</p>
        </div>
        <div className={"caregiver-middle-section"}>
          <p className={"caregiver-middle-section-title"}>Our top rated caregivers are here. Highly recommended for the best service for you</p>
          <div className={"carousel"} ref={flickityRef}>
            {topRatedContentArray.map((data, index) => (
              <TopRatedCaregiverCard data={data} key={index} />
            ))}
          </div>
        </div>
        <div className={"caregiver-right-section"}>
          <div className={"caregiver-right-section-inner"}>
            <div className={"filter-buttons"}>
              <p className={"filter-title"}>Filter</p>
              <p className={"reset-filter-title"}>Reset Filter</p>
            </div>
            <p className={"sister-type-title"}>Sister type</p>
            <div className={"checkboxes-section"}>
              <div className={"checkbox-item"}>
                <input type={"checkbox"} id={"newBorn"} />
                <label htmlFor={"newBorn"}>New Born</label>
              </div>
              <div className={"checkbox-item"}>
                <input type={"checkbox"} id={"localNanny"} />
                <label htmlFor={"localNanny"}>Local Nanny</label>
              </div>
              <div className={"checkbox-item"}>
                <input type={"checkbox"} id={"toddler"} />
                <label htmlFor={"toddler"}>Toddler</label>
              </div>
              <div className={"checkbox-item"}>
                <input type={"checkbox"} id={"babySitter"} />
                <label htmlFor={"babySitter"}>Baby Sitter</label>
              </div>
              <div className={"checkbox-item"}>
                <input type={"checkbox"} id={"nightCare"} />
                <label htmlFor={"nightCare"}>Night Care</label>
              </div>
            </div>
            <div className={"salary-range"}>
              <p>Range Salary</p>
              <p>Monthly</p>
            </div>
            <div className={"amount-range"}>
              <p>KES 9000</p>
              <span>-</span>
              <p>KES 4000</p>
            </div>
            <p className={"filter-location"}>NHC Langatta</p>
          </div>
          <p className={"caregiver-right-section-title"}>
            Advanced Search Filtering Option.
          </p>
          <p className={"caregiver-right-section-description"}>You can save time and focus your effort on the opportunities</p>
        </div>
      </div>
    </div>
  );
};
