import React from 'react'
import Logout from '../components/Logout'
import PostJobs from '../dashboard/PostJobs'
import AdminDashboard from '../dashboard/AdminDashboard'
import BlogPostForm from '../components/BlogPostForm'
import BlogPostList from '../components/BlogPostList'
import Notifications from '../components/Notifications'
import UserDataGrid from '../dashboard/UserDataGrid'
import ListJobs from '../dashboard/ListJobs'
import PostTrainingMaterial from '../dashboard/PostTrainingMaterial'
import Training from '../dashboard/Training'
import ViewProfile from '../components/viewProfile'
import CompleteProfile from '../components/CompleteProfile'
import ViewFullJobDetails from '../components/ViewFullJobDetails'
import Profile from '../components/Profile'
import MyJobApplications from '../components/MyJobApplications'
import MyTrainings from '../components/MyTrainings'
import MyJobs from '../components/MyJobs'
import ViewApplicants from '../components/viewAppliCants'

const TemplateDashboard = React.lazy(() => import('./views/dashboard/TemplateDashboard'))
const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))


const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: TemplateDashboard },
  { path: '/theme', name: 'Theme', element: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', element: Colors },
  { path: '/theme/typography', name: 'Typography', element: Typography },
  { path: '/base', name: 'Base', element: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', element: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', element: Cards },
  { path: '/base/carousels', name: 'Carousel', element: Carousels },
  { path: '/base/collapses', name: 'Collapse', element: Collapses },
  { path: '/base/list-groups', name: 'List Groups', element: ListGroups },
  { path: '/base/navs', name: 'Navs', element: Navs },
  { path: '/base/paginations', name: 'Paginations', element: Paginations },
  { path: '/base/placeholders', name: 'Placeholders', element: Placeholders },
  { path: '/base/popovers', name: 'Popovers', element: Popovers },
  { path: '/base/progress', name: 'Progress', element: Progress },
  { path: '/base/spinners', name: 'Spinners', element: Spinners },
  { path: '/base/tabs', name: 'Tabs', element: Tabs },
  { path: '/base/tables', name: 'Tables', element: Tables },
  { path: '/base/tooltips', name: 'Tooltips', element: Tooltips },
  { path: '/forms/range', name: 'Range', element: Range },
  { path: '/logout', name: 'Logout', element: Logout },
  { path: '/postJobs', name: 'Post Jobs', element: PostJobs },
  { path: '/adminDashboard', name: 'Admin Panel', element: AdminDashboard },
  { path: '/addBlogPost', name: 'Add Blog Post', element: BlogPostForm },
  { path: '/seePosts', name: 'See Posts', element: BlogPostList },
  { path: '/notifications', name: 'Notifications', element: Notifications },
  { path: '/viewusers', name: 'View Users', element: UserDataGrid },
  { path: '/blogs', name: 'Blogs', element: BlogPostList },
  { path: '/jobList', name: 'Job List', element: ListJobs },
  { path: '/addTraining', name: 'Add Training Material', element: PostTrainingMaterial },
  { path: '/training', name: 'Training', element: Training },
  { path: '/ViewProfile', name: 'View Profile', element: ViewProfile },
  { path: '/completeProfile', name: 'Complete Profile', element: CompleteProfile },
  { path: '/viewJob', name: 'View Job Details', element: ViewFullJobDetails },
  { path: '/profile', name: 'Profile', element: Profile },
  { path: '/JobApplications', name: 'Applications', element: MyJobApplications },
  { path: '/seeTrainings', name: 'See Trainings', element: MyTrainings },
  { path: '/MyJobs', name: 'My Jobs', element: MyJobs },
  { path: '/viewAppliCants', name: 'View Applicants', element: ViewApplicants },
  
]

export default routes
